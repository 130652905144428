import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import quoteInProgressClient from 'apiHelpers/quoteInProgressClient';
import { navigate, PageProps } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import { PageTitle } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useLoadingState from 'helpers/useLoadingState';
import { RESET_STATE } from 'state/actions';
import { ReferralCodes, useReferralCodes } from 'state/formData/referralCodes';

const StartQuote: React.FC<PageProps> = ({ location }) => {
  const dispatch = useDispatch();
  const [, updateReferralCodes] = useReferralCodes();

  const { withLoadingState } = useLoadingState();

  useEffect(() => {
    const validCodes = [
      ['affinity', getQueryParam(location, 'affinity')],
      ['subAffinity', getQueryParam(location, 'subAffinity')],
    ].filter(([, codeValue]) => !!codeValue);
    const referralCodes: ReferralCodes = Object.fromEntries(validCodes);

    withLoadingState(async () => {
      await quoteInProgressClient.deleteQuoteInProgress();
      dispatch({ type: RESET_STATE });
      updateReferralCodes(referralCodes);
      if (!isEmpty(referralCodes)) {
        await quoteInProgressClient.saveQuoteInProgress(referralCodes);
      }
      navigate(quoteAndBuyRoutes.aboutYourPet);
    });
  }, [dispatch, location, updateReferralCodes, withLoadingState]);

  return (
    <Layout pageTitle={PageTitle.StartQuote} metaTitle="Starting your quote">
      <LoadingOverlay loadingMessage="Starting your quote" />
    </Layout>
  );
};

export default StartQuote;
